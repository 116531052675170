<template>
  <v-container fluid>
    <v-row dense justify="space-between">
      <v-col cols="6">
        <v-text-field
            outlined
            v-model="inscricaoMUD.numeroEnem"
            :rules="[campoObrigatorioRule, tamanhoRule(12),processo.possuiPrefixoNumeroEnem ? prefixoRule(processo.listaPrefixoNumeroEnem) : true]"
            maxlength="12"
            v-mask="'############'"
            :counter="12"
            label="Número de inscrição no ENEM *"
            name="inscricaoNumeroEnem"
            validate-on-blur
            dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <MatriculaDRETextField v-model="inscricaoMUD.matricula" :label="'Matrícula'"
                               :rules="[validarMatriculaBaseadoNoNumeroEnem(inscricaoMUD.numeroEnem)]"/>
      </v-col>
      <v-col cols="12">
        <EscolhaOpcaoSelect :inscricao="inscricao" :opcoes="processo.opcoes"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import rules from "@/commons/rules";
import EscolhaOpcaoSelect from "@/components/pages/Inscricao/EscolhaOpcaoSelect";
import MatriculaDRETextField from "@/components/pages/Inscricao/MatriculaDRETextField";

export default {
  components: {MatriculaDRETextField, EscolhaOpcaoSelect},
  props: {
    processo: {type: Object, required: true},
    inscricao: {type: Object, required: true}
  },
  data() {
    return {
      inscricaoMUD: {
        inscricao: null,
        matricula: '',
        numeroEnem: '',
      }
    };
  },

  watch: {
    inscricaoMUD: {
      handler(){
        this.inscricaoMUD.inscricao = this.inscricao;
        this.$emit("input", this.inscricaoMUD);
      },
      deep: true
    }
  },

  methods: {
    ...rules
  }
};
</script>
