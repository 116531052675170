<template>
  <div>
    <v-autocomplete
        v-model="inscricao.opcao"
        :items="opcoes"
        :rules="[campoObrigatorioRule(inscricao.opcao.oid)]"
        dense
        :item-text="retornarItemName"
        :item-value="retornarItemValue"
        label="Opção de Curso Pleiteado *"
        outlined
        return-object
        validate-on-blur
        @input="tratarOpcaoCurso"
    >
      <template v-slot:selection="data">
        <v-chip-group>
          <v-sheet class="mx-1" color="grey lighten-2" rounded
          ><span class="mx-2 d-flex">{{ data.item.nomeOpcao }}</span>
          </v-sheet>
          <v-sheet class="mx-1" color="grey lighten-2" rounded
          ><span class="mx-2 d-flex">{{ data.item.turno }}</span>
          </v-sheet>
          <v-sheet class="mx-1" color="grey lighten-2" rounded>
            <span class="mx-2 d-flex">{{ data.item.polo }}</span>
          </v-sheet>
          <v-sheet class="mx-1" color="grey lighten-2" rounded>
            <span class="mx-2 d-flex">{{ data.item.formacao }}</span>
          </v-sheet>
        </v-chip-group>
      </template>
      <template v-slot:item="data">
        <v-chip-group>
          <v-sheet class="mx-1" color="grey lighten-2" rounded>
            <span class="mx-2 d-flex">{{ data.item.nomeOpcao }}</span>
          </v-sheet>
          <v-sheet class="mx-1" color="grey lighten-2" rounded>
            <span class="mx-2 d-flex">{{ data.item.turno }}</span>
          </v-sheet>
          <v-sheet class="mx-1" color="grey lighten-2" rounded>
            <span class="mx-2 d-flex">{{ data.item.polo }}</span>
          </v-sheet>
          <v-sheet class="mx-1" color="grey lighten-2" rounded>
            <span class="mx-2 d-flex">{{ data.item.formacao }}</span>
          </v-sheet>
        </v-chip-group>
      </template>
    </v-autocomplete>
    <v-alert
        :id="inscricao.opcao.oid" v-if="inscricao.opcao.observacao"
        auto-grow
        dense
        label="Observação"
        name="observacao"
        colored-border
        border="left"
        outlined
        icon="mdi-alert"
        type="warning"
        readonly
    >{{inscricao.opcao.observacao}}</v-alert>
  </div>
</template>

<script>
import rules from "@/commons/rules";

export default {
  name: "EscolhaOpcaoSelect",
  props: {
    opcoes: {type: Array, required: true},
    inscricao: {type: Object, required: true}
  },

  methods: {
    ...rules,
    retornarItemName(item) {
      return item.nomeOpcao;
    },
    retornarItemValue(item) {
      return item.oid;
    },
    tratarOpcaoCurso() {
      if (!this.inscricao.opcao.instrumentoMusicalRequerido) {
        this.inscricao.inscricaoInstrumentoMusical = null;
      } else {
        this.inscricao.inscricaoInstrumentoMusical = {
          instrumentoMusical: {
            nome: null,
            oid: null
          },
          nomeInstrumentoMusical: null
        }
      }
    },
  }
};
</script>
