<template>
  <v-text-field
      v-mask="'#########'"
      :counter="9"
      :label="`${label} *`"
      name="inscricaoMatriculaDRE"
      :rules="[
      campoObrigatorioRule,
      tamanhoRule(9),
      digitoVerificadorMatriculaDRERule,
      ...rules
    ]"
      :value="value"
      dense
      maxlength="9"
      outlined
      validate-on-blur
      @input="$emit('input', $event)"
  ></v-text-field>
</template>

<script>
import rules from "@/commons/rules";

export default {
  name: "MatriculaDRETextField",
  props: {
    value: {type: String},
    label: {type: String, required: true},
    rules: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    ...rules,

    digitoVerificadorMatriculaDRERule(matriculaDRE) {
      let matriculaSemDigitoVerificadorRecebida = parseInt(
          matriculaDRE.substring(0, 8)
      );
      const digitoVerificadorRecebido = parseInt(matriculaDRE.substring(8, 9));
      let soma = 0;
      let divisor = 10000000;

      for (let indice = 1; indice <= 8; indice++) {
        let digito = Math.floor(
            matriculaSemDigitoVerificadorRecebida / divisor
        );
        soma += digito * indice;
        matriculaSemDigitoVerificadorRecebida -= digito * divisor;
        divisor /= 10;
      }

      const digitoVerificadorCalculado = soma % 10;

      return (
          digitoVerificadorCalculado === digitoVerificadorRecebido ||
          "A matrícula digitada é inválida!"
      );
    }
  }
};
</script>
